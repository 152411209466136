export const arial8pt = {
  "32": 3.05615234375,
  "33": 3.05615234375,
  "34": 3.90478515625,
  "35": 6.11767578125,
  "36": 6.11767578125,
  "37": 9.78076171875,
  "38": 7.3369140625,
  "39": 2.10009765625,
  "40": 3.6630859375,
  "41": 3.6630859375,
  "42": 4.28076171875,
  "43": 6.423828125,
  "44": 3.05615234375,
  "45": 3.6630859375,
  "46": 3.05615234375,
  "47": 3.05615234375,
  "48": 6.11767578125,
  "49": 6.11767578125,
  "50": 6.11767578125,
  "51": 6.11767578125,
  "52": 6.11767578125,
  "53": 6.11767578125,
  "54": 6.11767578125,
  "55": 6.11767578125,
  "56": 6.11767578125,
  "57": 6.11767578125,
  "58": 3.05615234375,
  "59": 3.05615234375,
  "60": 6.423828125,
  "61": 6.423828125,
  "62": 6.423828125,
  "63": 6.11767578125,
  "64": 11.16650390625,
  "65": 7.3369140625,
  "66": 7.3369140625,
  "67": 7.94384765625,
  "68": 7.94384765625,
  "69": 7.3369140625,
  "70": 6.71923828125,
  "71": 8.55615234375,
  "72": 7.94384765625,
  "73": 3.05615234375,
  "74": 5.5,
  "75": 7.3369140625,
  "76": 6.11767578125,
  "77": 9.1630859375,
  "78": 7.94384765625,
  "79": 8.55615234375,
  "80": 7.3369140625,
  "81": 8.55615234375,
  "82": 7.94384765625,
  "83": 7.3369140625,
  "84": 6.71923828125,
  "85": 7.94384765625,
  "86": 7.3369140625,
  "87": 10.38232421875,
  "88": 7.3369140625,
  "89": 7.3369140625,
  "90": 6.71923828125,
  "91": 3.05615234375,
  "92": 3.05615234375,
  "93": 3.05615234375,
  "94": 5.16162109375,
  "95": 6.11767578125,
  "96": 3.6630859375,
  "97": 6.11767578125,
  "98": 6.11767578125,
  "99": 5.5,
  "100": 6.11767578125,
  "101": 6.11767578125,
  "102": 3.05615234375,
  "103": 6.11767578125,
  "104": 6.11767578125,
  "105": 2.44384765625,
  "106": 2.44384765625,
  "107": 5.5,
  "108": 2.44384765625,
  "109": 9.1630859375,
  "110": 6.11767578125,
  "111": 6.11767578125,
  "112": 6.11767578125,
  "113": 6.11767578125,
  "114": 3.6630859375,
  "115": 5.5,
  "116": 3.05615234375,
  "117": 6.11767578125,
  "118": 5.5,
  "119": 7.94384765625,
  "120": 5.5,
  "121": 5.5,
  "122": 5.5,
  "123": 3.673828125,
  "124": 2.857421875,
  "125": 3.673828125,
  "126": 6.423828125,
  "160": 3.05615234375,
  "161": 3.6630859375,
  "162": 6.11767578125,
  "163": 6.11767578125,
  "164": 6.11767578125,
  "165": 6.11767578125,
  "166": 2.857421875,
  "167": 6.11767578125,
  "168": 3.6630859375,
  "169": 8.10498046875,
  "170": 4.0712890625,
  "171": 6.11767578125,
  "172": 6.423828125,
  "173": 0,
  "174": 8.10498046875,
  "175": 6.07470703125,
  "176": 4.39892578125,
  "177": 6.037109375,
  "178": 3.6630859375,
  "179": 3.6630859375,
  "180": 3.6630859375,
  "181": 6.337890625,
  "182": 5.908203125,
  "183": 3.6630859375,
  "184": 3.6630859375,
  "185": 3.6630859375,
  "186": 4.017578125,
  "187": 6.11767578125,
  "188": 9.173828125,
  "189": 9.173828125,
  "190": 9.173828125,
  "191": 6.71923828125,
  "192": 7.3369140625,
  "193": 7.3369140625,
  "194": 7.3369140625,
  "195": 7.3369140625,
  "196": 7.3369140625,
  "197": 7.3369140625,
  "198": 11,
  "199": 7.94384765625,
  "200": 7.3369140625,
  "201": 7.3369140625,
  "202": 7.3369140625,
  "203": 7.3369140625,
  "204": 3.05615234375,
  "205": 3.05615234375,
  "206": 3.05615234375,
  "207": 3.05615234375,
  "208": 7.94384765625,
  "209": 7.94384765625,
  "210": 8.55615234375,
  "211": 8.55615234375,
  "212": 8.55615234375,
  "213": 8.55615234375,
  "214": 8.55615234375,
  "215": 6.423828125,
  "216": 8.55615234375,
  "217": 7.94384765625,
  "218": 7.94384765625,
  "219": 7.94384765625,
  "220": 7.94384765625,
  "221": 7.3369140625,
  "222": 7.3369140625,
  "223": 6.71923828125,
  "224": 6.11767578125,
  "225": 6.11767578125,
  "226": 6.11767578125,
  "227": 6.11767578125,
  "228": 6.11767578125,
  "229": 6.11767578125,
  "230": 9.78076171875,
  "231": 5.5,
  "232": 6.11767578125,
  "233": 6.11767578125,
  "234": 6.11767578125,
  "235": 6.11767578125,
  "236": 3.05615234375,
  "237": 3.05615234375,
  "238": 3.05615234375,
  "239": 3.05615234375,
  "240": 6.11767578125,
  "241": 6.11767578125,
  "242": 6.11767578125,
  "243": 6.11767578125,
  "244": 6.11767578125,
  "245": 6.11767578125,
  "246": 6.11767578125,
  "247": 6.037109375,
  "248": 6.71923828125,
  "249": 6.11767578125,
  "250": 6.11767578125,
  "251": 6.11767578125,
  "252": 6.11767578125,
  "253": 5.5,
  "254": 6.11767578125
};

export const arial10pt = {
  "32": 3.61181640625,
  "33": 3.61181640625,
  "34": 4.61474609375,
  "35": 7.22998046875,
  "36": 7.22998046875,
  "37": 11.55908203125,
  "38": 8.6708984375,
  "39": 2.48193359375,
  "40": 4.3291015625,
  "41": 4.3291015625,
  "42": 5.05908203125,
  "43": 7.591796875,
  "44": 3.61181640625,
  "45": 4.3291015625,
  "46": 3.61181640625,
  "47": 3.61181640625,
  "48": 7.22998046875,
  "49": 7.22998046875,
  "50": 7.22998046875,
  "51": 7.22998046875,
  "52": 7.22998046875,
  "53": 7.22998046875,
  "54": 7.22998046875,
  "55": 7.22998046875,
  "56": 7.22998046875,
  "57": 7.22998046875,
  "58": 3.61181640625,
  "59": 3.61181640625,
  "60": 7.591796875,
  "61": 7.591796875,
  "62": 7.591796875,
  "63": 7.22998046875,
  "64": 13.19677734375,
  "65": 8.6708984375,
  "66": 8.6708984375,
  "67": 9.38818359375,
  "68": 9.38818359375,
  "69": 8.6708984375,
  "70": 7.94091796875,
  "71": 10.11181640625,
  "72": 9.38818359375,
  "73": 3.61181640625,
  "74": 6.5,
  "75": 8.6708984375,
  "76": 7.22998046875,
  "77": 10.8291015625,
  "78": 9.38818359375,
  "79": 10.11181640625,
  "80": 8.6708984375,
  "81": 10.11181640625,
  "82": 9.38818359375,
  "83": 8.6708984375,
  "84": 7.94091796875,
  "85": 9.38818359375,
  "86": 8.6708984375,
  "87": 12.27001953125,
  "88": 8.6708984375,
  "89": 8.6708984375,
  "90": 7.94091796875,
  "91": 3.61181640625,
  "92": 3.61181640625,
  "93": 3.61181640625,
  "94": 6.10009765625,
  "95": 7.22998046875,
  "96": 4.3291015625,
  "97": 7.22998046875,
  "98": 7.22998046875,
  "99": 6.5,
  "100": 7.22998046875,
  "101": 7.22998046875,
  "102": 3.61181640625,
  "103": 7.22998046875,
  "104": 7.22998046875,
  "105": 2.88818359375,
  "106": 2.88818359375,
  "107": 6.5,
  "108": 2.88818359375,
  "109": 10.8291015625,
  "110": 7.22998046875,
  "111": 7.22998046875,
  "112": 7.22998046875,
  "113": 7.22998046875,
  "114": 4.3291015625,
  "115": 6.5,
  "116": 3.61181640625,
  "117": 7.22998046875,
  "118": 6.5,
  "119": 9.38818359375,
  "120": 6.5,
  "121": 6.5,
  "122": 6.5,
  "123": 4.341796875,
  "124": 3.376953125,
  "125": 4.341796875,
  "126": 7.591796875,
  "160": 3.61181640625,
  "161": 4.3291015625,
  "162": 7.22998046875,
  "163": 7.22998046875,
  "164": 7.22998046875,
  "165": 7.22998046875,
  "166": 3.376953125,
  "167": 7.22998046875,
  "168": 4.3291015625,
  "169": 9.57861328125,
  "170": 4.8115234375,
  "171": 7.22998046875,
  "172": 7.591796875,
  "173": 0,
  "174": 9.57861328125,
  "175": 7.17919921875,
  "176": 5.19873046875,
  "177": 7.134765625,
  "178": 4.3291015625,
  "179": 4.3291015625,
  "180": 4.3291015625,
  "181": 7.490234375,
  "182": 6.982421875,
  "183": 4.3291015625,
  "184": 4.3291015625,
  "185": 4.3291015625,
  "186": 4.748046875,
  "187": 7.22998046875,
  "188": 10.841796875,
  "189": 10.841796875,
  "190": 10.841796875,
  "191": 7.94091796875,
  "192": 8.6708984375,
  "193": 8.6708984375,
  "194": 8.6708984375,
  "195": 8.6708984375,
  "196": 8.6708984375,
  "197": 8.6708984375,
  "198": 13,
  "199": 9.38818359375,
  "200": 8.6708984375,
  "201": 8.6708984375,
  "202": 8.6708984375,
  "203": 8.6708984375,
  "204": 3.61181640625,
  "205": 3.61181640625,
  "206": 3.61181640625,
  "207": 3.61181640625,
  "208": 9.38818359375,
  "209": 9.38818359375,
  "210": 10.11181640625,
  "211": 10.11181640625,
  "212": 10.11181640625,
  "213": 10.11181640625,
  "214": 10.11181640625,
  "215": 7.591796875,
  "216": 10.11181640625,
  "217": 9.38818359375,
  "218": 9.38818359375,
  "219": 9.38818359375,
  "220": 9.38818359375,
  "221": 8.6708984375,
  "222": 8.6708984375,
  "223": 7.94091796875,
  "224": 7.22998046875,
  "225": 7.22998046875,
  "226": 7.22998046875,
  "227": 7.22998046875,
  "228": 7.22998046875,
  "229": 7.22998046875,
  "230": 11.55908203125,
  "231": 6.5,
  "232": 7.22998046875,
  "233": 7.22998046875,
  "234": 7.22998046875,
  "235": 7.22998046875,
  "236": 3.61181640625,
  "237": 3.61181640625,
  "238": 3.61181640625,
  "239": 3.61181640625,
  "240": 7.22998046875,
  "241": 7.22998046875,
  "242": 7.22998046875,
  "243": 7.22998046875,
  "244": 7.22998046875,
  "245": 7.22998046875,
  "246": 7.22998046875,
  "247": 7.134765625,
  "248": 7.94091796875,
  "249": 7.22998046875,
  "250": 7.22998046875,
  "251": 7.22998046875,
  "252": 7.22998046875,
  "253": 6.5,
  "254": 7.22998046875
};

export function calculateTextWidth(text: string, size: "8pt" | "10pt") {
  let width = 0;
  if(size === "8pt"){
    for(let c = 0; c < text.length; c++){
      width += arial8pt[text.charCodeAt(c)];
    }
  } else if(size === "10pt"){
    for(let c = 0; c < text.length; c++){
      width += arial10pt[text.charCodeAt(c)];
    }
  }
  return width;
}